
import {
  IonPage,
  IonContent,
  IonSpinner,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonSearchbar,
  IonSlides,
  IonSlide,
  IonButtons,
  IonImg,
} from "@ionic/vue";
import { defineComponent, reactive, onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import services from "../../services";
import Empty from "../../components/Empty.vue";
import { Geolocation } from "@capacitor/geolocation";

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonSpinner,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonSearchbar,
    IonSlides,
    IonSlide,
    IonButtons,
    IonImg,
    Empty,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      important: "",
      contentLoading: false,
      isActive: false,
      search: "",
      // page: 1,
      // size: 5,
      totalLength: 0,
      mission: {
        loading: true,
        items: [{}],
        limit: 0,
        allItems: [],
        totalItems: 0,
        totalPages: 0,
      },
      festival: {
        loading: true,
        items: [{}],
        limit: 0,
        allItems: [],
        totalItems: 0,
        totalPages: 0,
      },
      badge: {
        loading: true,
        items: [{}],
        limit: 0,
        allItems: [],
        totalItems: 0,
        totalPages: 0,
      },
      stamp: {
        loading: true,
        items: [{}],
        limit: 0,
        allItems: [],
        totalItems: 0,
        totalPages: 0,
      },
      groupDistricts: {
        loading: true,
        items: [],
        totalItems: 0,
        totalPages: 0,
      },
      groupCategory: {
        loading: true,
        items: [],
        totalItems: 0,
        totalPages: 0,
      },
      geolocation: {
        lat: 0,
        lng: 0,
      },
    });

    const onGroupLink = (id: number, title: string) => {
      store.commit("page", {
        title: title,
      });
      router.push({
        name: "GroupId",
        params: { id: id },
      });
    };

    const slideOpts = {
      slidesPerView: "auto",
      speed: 400,
      pagination: false,
    };

    const onMissionLoad = (limit = 5) => {
      state.mission.limit = state.mission.limit + limit;

      const items = state.mission.allItems;
      console.log(items);
      state.mission.items = [];
      if (items) {
        items.some((item, index) => {
          if (index < state.mission.limit) {
            state.mission.items.push(item);
          } else {
            return false;
          }
        });
      }
    };

    const onFestivalLoad = (limit = 10) => {
      state.festival.limit = state.festival.limit + limit;

      const items = state.festival.allItems;
      state.festival.items = [];
      if (items) {
        items.some((item, index) => {
          if (index < state.festival.limit) {
            state.festival.items.push(item);
          } else {
            return false;
          }
        });
      }
    };

    const onBadgeLoad = (limit = 10) => {
      state.badge.limit = state.badge.limit + limit;

      const items = state.badge.allItems;
      state.badge.items = [];
      if (items) {
        items.some((item, index) => {
          if (index < state.badge.limit) {
            state.badge.items.push(item);
          } else {
            return false;
          }
        });
      }
    };

    const onStampLoad = (limit = 10) => {
      state.stamp.limit = state.stamp.limit + limit;

      const items = state.stamp.allItems;
      state.stamp.items = [];
      if (items) {
        items.some((item, index) => {
          if (index < state.stamp.limit) {
            state.stamp.items.push(item);
          } else {
            return false;
          }
        });
      }
    };

    const fetch = async (event: any = null) => {
      state.important = "";
      await services.groupDistrictsFineAll().then(
        (response) => {
          const { data } = response;
          if (event) {
            if (event.type == "ion-refresh") {
              state.groupDistricts.items = [];
            }
          }

          state.groupDistricts.items = data.items;
          state.groupDistricts.totalItems = data.totalItems;
          state.groupDistricts.totalPages = data.totalPages;
          state.groupDistricts.loading = false;
        },
        (error) => {
          if (event) {
            if (event.type == "ion-refresh") {
              state.groupDistricts.items = [];
            }
          }
          state.groupDistricts.loading = false;
        }
      );
      await services.groupCategoryFindAll().then(
        (response) => {
          const { data } = response;

          if (event) {
            if (event.type == "ion-refresh") {
              state.groupCategory.items = [];
            }
          }

          state.groupCategory.items = data.items;
          state.groupCategory.totalItems = data.totalItems;
          state.groupCategory.totalPages = data.totalPages;
          state.groupCategory.loading = false;
        },
        (error) => {
          if (event) {
            if (event.type == "ion-refresh") {
              state.groupCategory.items = [];
            }
          }
          state.groupCategory.loading = false;
        }
      );
    };

    const search = async (important: any = null) => {
      state.important = important;

      // 초기화
      state.mission.limit = 0;
      state.mission.allItems = [];
      state.mission.totalItems = 0;
      state.mission.totalPages = 0;

      state.festival.limit = 0;
      state.festival.allItems = [];
      state.festival.totalItems = 0;
      state.festival.totalPages = 0;

      state.badge.limit = 0;
      state.badge.allItems = [];
      state.badge.totalItems = 0;
      state.badge.totalPages = 0;

      state.stamp.limit = 0;
      state.stamp.allItems = [];
      state.stamp.totalItems = 0;
      state.stamp.totalPages = 0;

      state.totalLength = 0;

      const params = {
        important: important,
      };

      if ( state.geolocation.lat != 0 && state.geolocation.lng != 0 ) {
        Object.assign(params, {
          lat: state.geolocation.lat,
          lng: state.geolocation.lng
        });
      }

      if (important) {
        state.contentLoading = true;
      } else {
        return;
      }

      await services.missionRewardFindAll(params).then(
        (response) => {
          const { data } = response;
          console.log(data.items);
          data.items.some((item: any) => {
            if (item.completeStampCount < item.acquisitionStampCount) {
              item.acquisitionStampCount = item.completeStampCount;
            }
          });

          state.mission.allItems = data.items;
          state.mission.totalItems = data.totalItems;
          state.mission.totalPages = data.totalPages;
          state.mission.loading = false;
          onMissionLoad(5);
        },
        (error) => {
          state.mission.loading = false;
        }
      );
      await services.festivalFineAll(params).then(
        (response) => {
          const { data } = response;
          state.festival.allItems = data.items;
          state.festival.totalItems = data.totalItems;
          state.festival.totalPages = data.totalPages;
          state.festival.loading = false;
          onFestivalLoad(10);
        },
        (error) => {
          state.festival.allItems = [];
          state.festival.totalItems = 0;
          state.festival.totalPages = 0;
          state.festival.loading = false;
        }
      );
      await services.missionBadgeFindAll(params).then(
        (response) => {
          const { data } = response;
          console.log(data);
          state.badge.allItems = data.items;
          state.badge.totalItems = data.totalItems;
          state.badge.totalPages = data.totalPages;
          state.badge.loading = false;
          onBadgeLoad(10);
        },
        (error) => {
          state.badge.allItems = [];
          state.badge.totalItems = 0;
          state.badge.totalPages = 0;
          state.badge.loading = false;
        }
      );
      await services.stampFindAll(params).then(
        (response) => {
          const { data } = response;
          console.log(data);
          state.stamp.allItems = data.items;
          state.stamp.totalItems = data.totalItems;
          state.stamp.totalPages = data.totalPages;
          state.stamp.loading = false;
          onStampLoad(10);
        },
        (error) => {
          state.stamp.allItems = [];
          state.stamp.totalItems = 0;
          state.stamp.totalPages = 0;
          state.stamp.loading = false;
        }
      );
      state.contentLoading = false;

      state.totalLength =
        state.mission.allItems.length +
        state.festival.allItems.length +
        state.badge.allItems.length +
        state.stamp.allItems.length;
    };

    const onSearch = () => {
      state.mission.items = [];
      state.mission.totalItems = 0;
      state.festival.items = [];
      state.festival.totalItems = 0;
      state.badge.items = [];
      state.badge.totalItems = 0;
      state.stamp.items = [];
      state.stamp.totalItems = 0;
      search(state.search);
    };

    const searchCleared = () => {
      state.search = "";
    };

    const onLocation = async () => {
      const getGeoLocation = store.getters["getGeoLocation"];
      if (getGeoLocation.lat && getGeoLocation.lng) {
        state.geolocation.lat = getGeoLocation.lat;
        state.geolocation.lng = getGeoLocation.lng;
      }

      if (state.geolocation.lat == 0 && state.geolocation.lat == 0) {
        Geolocation.getCurrentPosition({
          enableHighAccuracy: true,
          timeout: 1000,
        })
          .then((response) => {
            const latitude = response.coords.latitude;
            const longitude = response.coords.longitude;

            store.commit("geolocation", {
              lat: latitude,
              lng: longitude,
            });

            state.geolocation.lat = latitude;
            state.geolocation.lng = longitude;
            console.log("helloGeolocation 3");
            state.contentLoading = false;
          })
          .catch((error) => {
            console.log(error.message);
            state.contentLoading = false;
          });
      } else {
        state.contentLoading = false;
      }
    };

    onMounted(() => {
      state.mission.items = [];
      state.mission.totalItems = 0;
      state.festival.items = [];
      state.festival.totalItems = 0;
      state.badge.items = [];
      state.badge.totalItems = 0;
      state.stamp.items = [];
      state.stamp.totalItems = 0;
      onLocation();
      fetch();
    });

    return {
      state,
      searchCleared,
      onSearch,
      onMissionLoad,
      onFestivalLoad,
      onBadgeLoad,
      onStampLoad,
      onGroupLink,
      slideOpts,
    };
  },
});
